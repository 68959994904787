export const gridSectionOne = [
  {
    area: "a",
    order: 0,
    height: 1135,
    width: 850,
    transform: {
      desktop: "translateX(25%)",
      mobile: "",
    },
  },
  {
    area: "b",
    order: 1,
    height: 760,
    width: 1350,
    transform: {
      desktop: "translateY(30%)",
      mobile: "",
    },
  },
  {
    area: "c",
    order: 2,
    height: 745,
    width: 745,
    transform: {
      desktop: "translateY(45%) scale(0.8) ",
      mobile: "",
    },
  },
  {
    area: "d",
    order: 3,
    height: 850,
    width: 850,
    transform: {
      desktop: "translateX(20%) scale(1.2)",
      mobile: "",
    },
  },
  {
    area: "e",
    order: 4,
    height: 1100,
    width: 825,
    transform: {
      desktop: "scale(0.8) translate(10%, 5%)",
      mobile: "scale(0.8) translate(-5%, 20%) ",
    },
  },
];

const gridData = [
  {
    area: "a",
    order: 0,
    height: 1135,
    width: 850,
    transform: {
      desktop: "translateX(25%)",
      mobile: "",
    },
  },
  {
    area: "b",
    order: 1,
    height: 760,
    width: 1350,
    transform: {
      desktop: "translateY(30%)",
      mobile: "",
    },
  },
  {
    area: "c",
    order: 2,
    height: 745,
    width: 745,
    transform: {
      desktop: "translateY(45%) scale(0.8) ",
      mobile: "",
    },
  },
  {
    area: "d",
    order: 3,
    height: 850,
    width: 850,
    transform: {
      desktop: "translateX(20%) scale(1.2)",
      mobile: "",
    },
  },
  {
    area: "e",
    order: 4,
    height: 1100,
    width: 825,
    transform: {
      desktop: "scale(0.8) translate(10%, 5%)",
      mobile: "scale(0.8) translate(-5%, 20%) ",
    },
  },
  {
    order: 5,
    height: 785,
    width: 585,
    transform: {
      desktop: "scale(1.1) translate(5%, -20%)",
      mobile: " ",
    },
  },
  {
    order: 6,
    height: 1950,
    width: 1450,
    transform: {
      desktop: "translateX(-2%)",
      mobile: "",
    },
  },
  {
    order: 7,
    height: 1030,
    width: 775,
  },
  {
    order: 8,
    height: 785,
    width: 585,
    transform: {
      desktop: "scale(1.1) translate(-10%, 5%)",
      mobile: "translate(2.5%, 55%) scale(0.9)",
    },
  },
  {
    order: 9,
    height: 786,
    width: 585,
    transform: { desktop: "scale(1.1) translateX(15%)", mobile: "scale(0.85)" },
  },
  {
    order: 10,
    height: 1950,
    width: 1450,
    transform: { desktop: "scale(1.1) translate(-5%, 20%)", mobile: "" },
  },
  {
    order: 11,
    height: 850,
    width: 850,
    transform: { desktop: "translateY(-10%)", mobile: "" },
  },
  {
    order: 12,
    height: 900,
    width: 1600,
  },
  {
    order: 13,
    height: 1100,
    width: 825,
    transform: {
      desktop: "translateY(10%)",
      mobile: "scale(0.8)",
    },
  },
  {
    order: 14,
    height: 1000,
    width: 780,
    transform: {
      desktop: "scale(1.1) translate(-5%,-20%)",
      mobile: "scale(1.1) translateX(-5%)",
    },
  },
  {
    order: 15,
    height: 1145,
    width: 1145,
    transform: { desktop: "scale(0.9) translateY(-4%)", mobile: "" },
  },
  {
    order: 16,
    height: 850,
    width: 850,
    transform: {
      desktop: "scale(0.8) translateY(-50%)",
      mobile: "translateY(50%)",
    },
  },
  {
    order: 17,
    height: 1000,
    width: 780,
    transform: { desktop: "translate(-10%,10%)", mobile: "translateY(-20%)" },
  },
  {
    order: 18,
    height: 1700,
    width: 1700,
    transform: { desktop: "translateY(-10%)", mobile: "" },
  },
  {
    order: 19,
    height: 970,
    width: 730,
    transform: { desktop: "", mobile: "translateY(50%)" },
  },

  {
    order: 20,
    height: 1100,
    width: 825,
    transform: { desktop: "translateY(4%)", mobile: "" },
  },
  {
    order: 21,
    height: 850,
    width: 850,
    transform: { desktop: "scale(0.8) translate(12%,-20%)", mobile: "" },
  },
  {
    order: 22,
    height: 1890,
    width: 1418,
  },
  {
    order: 23,
    height: 1050,
    width: 785,
    transform: { desktop: "scale(0.75) ", mobile: "scale(0.9)" },
  },

  {
    order: 24,
    height: 708,
    width: 980,
    transform: {
      desktop: "translateY(-35%)",
      mobile: "scale(1.4) translate(-15%, 10%)",
    },
  },
  {
    order: 25,
    height: 707,
    width: 942,
    transform: { desktop: "", mobile: "translateY(20%)" },
  },
];

export const gridSectionTwo = {
  desktop: gridData.filter((p) => p.order === 5),
  mobile: gridData.filter((p) => p.order === 5 || p.order === 6),
};

export const gridSectionThree = {
  desktop: gridData.filter((p) => [6, 7, 8, 9, 10].includes(p.order)),
  mobile: gridData.filter((p) => [7, 8].includes(p.order)),
};

export const gridSectionFour = {
  desktop: gridData.filter((p) => [11, 12, 13].includes(p.order)),
  mobile: gridData.filter((p) => [9, 10, 11].includes(p.order)),
};

export const gridSectionFive = {
  desktop: gridData.filter((p) => [14, 15, 16, 17].includes(p.order)),
  mobile: gridData.filter((p) => [12].includes(p.order)),
};

export const gridSectionSix = {
  desktop: gridData.filter((p) => [18, 19, 20].includes(p.order)),
  mobile: gridData.filter((p) => [13, 14, 15, 16, 17].includes(p.order)),
};

export const gridSectionSeven = {
  desktop: gridData.filter((p) => [21, 22, 23, 24, 25].includes(p.order)),
  mobile: gridData.filter((p) => [18, 19, 20, 21].includes(p.order)),
};

export const gridSectionEight = {
  desktop: gridData.filter((p) => [].includes(p.order)),
  mobile: gridData.filter((p) => [22, 23, 24, 25].includes(p.order)),
};

export default gridData;
