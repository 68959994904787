import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import { useLanguage } from "../contexts/languageContext";
import { getIndividualRoute } from "../routes";

const LandingPageGatsbyImage = ({
  look,
  gatsbyImageData,
  alt = "img",
  style,
  aspectRatio,
  loading,
  disabled,
  onLoad,
  objectPosition = "top",
}) => {
  const ref = useRef();
  const { region, entered } = useLanguage();
  const image = getImage(gatsbyImageData);
  const [loaded, setLoaded] = useState(false);
  // const loadingMethod = isMobile && !loading ? "lazy" : loading || "eager";
  const loadingMethod = "eager";

  const path = `/${region}/${getIndividualRoute(look?.lookid)}`;

  useEffect(() => {
    // check if loaded
    if (!loaded && entered) {
      const img = ref.current?.querySelector("img");
      if (img?.complete) {
        setLoaded(true);
        // onLoad?.();
      }
    }
  }, [loaded, onLoad, entered]);

  useEffect(() => {
    if (loaded) {
      onLoad?.(look);
    }
  }, [loaded, onLoad]);

  if (!image) return null;
  return (
    <div
      style={{
        width: "100%",
        aspectRatio,
      }}
      data-look={look.id}
      ref={ref}
      className="animation_target"
    >
      {!disabled ? (
        <Link to={path}>
          <GatsbyImage
            image={image}
            alt={alt}
            style={style}
            objectFit="cover"
            objectPosition={objectPosition}
            loading={loadingMethod}
            onLoad={() => setLoaded(true)}
          />
        </Link>
      ) : (
        <GatsbyImage
          image={image}
          alt={alt}
          style={style}
          objectFit="cover"
          objectPosition={objectPosition}
          loading={loadingMethod}
          onLoad={() => setLoaded(true)}
        />
      )}
    </div>
  );
};
export default LandingPageGatsbyImage;
