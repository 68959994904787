import gsap from "gsap";
import React, { useCallback, useEffect, useState } from "react";
import random from "lodash/random";

const BackgroundAnimation = () => {
  const [backgroundColor, setBackgroundColor] = useState("#fff");

  const animateBackground = useCallback(() => {
    let i = { value: 0 };
    gsap.to(i, {
      value: random(30, 255),
      duration: 0.5,
      ease: "steps(2)",
      repeat: -1,
      onUpdate: (v) => {
        setBackgroundColor(`rgb(${i.value},${i.value},${i.value})`);
      },
      onUpdateParams: [i],
    });
  }, []);

  useEffect(() => {
    animateBackground();
  }, [animateBackground]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        background: backgroundColor,
      }}
    />
  );
};
export default BackgroundAnimation;
