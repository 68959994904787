export const getLook = (rawData) => {
  return {
    id: rawData?.look?.document?.id,
    ...rawData?.look?.document?.data,
    // tags: rawData?.look?.document?.data?.tags?.map((t) => ({
    //   ...t.tag.document.data,
    // })),
  };
};
export const getLooks = (rawData, limit) => {
  const length = limit || rawData?.looks?.length;
  return (rawData?.looks?.map((look) => getLook(look)) || []).slice(0, length);
};

export const getProducts = (products) => {
  return (
    products.map((product) => {
      return product.product.document.data;
    }) || []
  );
};

export const getLandingPageLooks = (rawData, limit) => {
  const length = limit || rawData?.landing_page_sequence?.length;
  return (
    rawData?.landing_page_sequence?.map((look, idx) => {
      return {
        id: `${look.individual?.document?.id}_${idx}`,
        image: look?.image,
        ...look?.individual?.document?.data,
      };
    }) || []
  ).slice(0, length);
};
