import * as React from "react";
import { graphql, navigate } from "gatsby";
import { useLanguage } from "../contexts/languageContext";
import { useMemo } from "react";
import { getLandingPageLooks } from "../utils/processData";
import StickySideBar from "../components/StickySideBar";
import {
  Section1,
  GridItem,
  HeaderGrid,
  SpotifyGrid,
  Section2Desktop,
  Section2Mobile,
  Wrapper,
  Section2MobileHeader,
  Section3Desktop,
  Section3Mobile,
  Subtitle,
  SectionDescription,
  SectionFourMobile,
  SectionFourDesktop,
  MobileContentWrapper,
  SectionFiveMobile,
  SectionFiveDesktop,
  SectionSixMobile,
  SectionSevenMobile,
  SectionHeader,
  SectionEightMobile,
  PageFooter,
  SectionSixDesktop,
  DummyDiv,
  SectionSevenDesktop,
  PageWrapper,
  AnimatableContainer,
} from "../styles/homepage";
import gsap from "gsap";
import SpotifyPlayer from "../components/SpotifyPlayer";
import LandingPageGatsbyImage from "../components/LandingPageGatsbyImage";
import {
  gridSectionEight,
  gridSectionFive,
  gridSectionFour,
  gridSectionOne,
  gridSectionSeven,
  gridSectionSix,
  gridSectionThree,
  gridSectionTwo,
} from "../data/homepageGridData";
import GatsbyImageComponent from "../components/GatsbyImage";
import { splitLines } from "../utils/processText";
import { BorderedButton } from "../styles/common";
import { useState } from "react";
import { useEffect } from "react";
import BackgroundAnimation from "../components/BackgroundAnimation";
import routes from "../routes";

const SectionGrid = ({
  component: Component,
  data,
  looks,
  children,
  onLoad,
}) => {
  const { isMobile } = useLanguage();

  return (
    <Component>
      {children}
      {data.map((grid) =>
        !looks[grid.order] ? null : (
          <LandingPageGatsbyImage
            key={grid.order}
            look={looks[grid.order]}
            gatsbyImageData={looks[grid.order].image.gatsbyImageData}
            aspectRatio={grid.width / grid.height}
            style={{
              transform: isMobile
                ? grid.transform?.mobile
                : grid.transform?.desktop,
              height: "100%",
            }}
            onLoad={onLoad}
          />
        )
      )}
    </Component>
  );
};
const HomepageTemplate = ({ data }) => {
  const [pageLoaded, setPageLoaded] = useState();
  const [loadingProgress, setLoadingProgress] = useState(0);

  const {
    entered,
    region,
    lang,
    theme,
    setDisableScrolling,
    scrollToTop,
    isMobile,
    setFilteredLooks
  } = useLanguage();

  const preloadImagesCount = useMemo(() => (isMobile ? 5 : 15), [isMobile]);
  const centerAnimationOffsetY = 0;

  React.useEffect(() => {
    setPageLoaded(true);
  }, []);

  const ref = React.useRef();
  const speakRef = React.useRef();

  const [positions, setPositions] = React.useState([]);
  const [starterAnimation, setStartingAnimation] = React.useState(false);
  const [imageLoaded, setImageLoaded] = useState([]);

  const [animationStarted, setAnimatedStarted] = useState(false);
  const [stackingAnimationDone, setStackingAnimationgDone] = useState(false);

  const [animationCompelted, setAnimationCompleted] = useState(false);

  const { copies, generalCopies, looks, spotifyUrl, attitudeImages } =
    useMemo(() => {
      return {
        copies: data?.allPrismicCopiesHomepage?.nodes?.find(
          (h) => h.lang === lang
        )?.data,
        generalCopies: data?.allPrismicCopiesGeneral?.nodes?.find(
          (h) => h.lang === lang
        )?.data,
        looks: getLandingPageLooks(data.prismicDataLooksByRegion?.data),
        spotifyUrl: data?.prismicDataLooksByRegion.data.spotify_playlist,
        attitudeImages: data?.allPrismicDataAttitudeImages?.nodes?.find(
          (h) => h.lang === lang
        )?.data,
      };
    }, [data, lang]);

  React.useEffect(() => {
    setDisableScrolling(true);
    return () => {
      setDisableScrolling(false);
    };
  }, [setDisableScrolling, starterAnimation]);

  const spreadOut = React.useCallback(
    (positionRefs) => {
      const allGrids = document.getElementsByClassName("movable_grid");
      const displayGrids = document.getElementsByClassName("displayGrid");

      const allAnimatableGrids = Array.from(
        document.getElementsByClassName("animation_target")
      );

      const children = Array.from(allGrids);
      children.forEach((p, idx) => {
        if (idx > 7) {
          gsap.to(p, {
            opacity: 0,
            // zIndex: -10,
            duration: 0.1,
          });
          return;
        }
        const pos = positionRefs.find(
          (item) => `movable_grid_${item.id}` === p.id
        );
        if (!pos) return;

        const tl = gsap.timeline();

        setStackingAnimationgDone(true);
        tl.add("swap");
        tl.to(p, {
          left: pos.x,
          top: pos.y,
        })
          .to(
            p,
            {
              opacity: 0,
              delay: 0.5,
              // zIndex: -10,
              onComplete: () => {
                setTimeout(() => {
                  setDisableScrolling(false);
                }, 100);
              },
            },
            "swap"
          )
          .to(allAnimatableGrids[idx], { opacity: 1, delay: 0.5 }, "swap");
      });
      const timeline = gsap.timeline();
      timeline
        .to(allAnimatableGrids, { delay: 0.4, opacity: 1 }, 0)
        .to(displayGrids, { opacity: 1 }, 0.25);
      gsap.set(allGrids, { zIndex: 0 });
      setAnimationCompleted(true);
    },
    [setDisableScrolling]
  );

  const getAllPositions = React.useCallback(() => {
    const children = Array.from(
      document.getElementsByClassName("animation_target")
    ).slice(0, 9);

    gsap.set(children, { opacity: 0 });
    const offset = ref.current.getBoundingClientRect();

    setPositions(() => {
      const newPos = children.map((p) => {
        const location = p.getBoundingClientRect();
        return {
          id: p.dataset.look,
          x: location.x,
          y: location.y - offset.y,
          top: location.top - offset.top,
          left: location.left - offset.left,
          width: location.width,
          height: location.height,
        };
      });
      return newPos;
    });
  }, []);

  const scrollToSpeaker = React.useCallback(() => {
    speakRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  }, []);

  React.useEffect(() => {
    if (entered && pageLoaded) {
      getAllPositions();
    }
  }, [entered, pageLoaded, getAllPositions]);


  React.useEffect(() => {
      setFilteredLooks(looks)
  }, [setFilteredLooks, looks])

  const onImageLoaded = React.useCallback((look) => {
    setImageLoaded((prev) => {
      if (prev.includes(look.lookid)) return prev;
      return [...prev, look.lookid];
    });
  }, []);

  const exploreMore = React.useCallback(() => {
    navigate(`/${region}/${routes.filter}`);
  }, [region]);

  const updateLoader = React.useCallback(
    (loadedList) => {
      let i = { value: loadingProgress };
      if (loadedList.length / preloadImagesCount > 1 && loadingProgress >= 1) {
        return;
      }
      gsap.to(i, {
        duration: 0.1,
        value:
          loadedList.length / preloadImagesCount > 1
            ? 1
            : loadedList.length / preloadImagesCount,
        onUpdate: (v) => {
          if (i.value <= 1) {
            setLoadingProgress(i.value);
          }
        },
        onUpdateParams: [i],
      });
    },
    [loadingProgress, preloadImagesCount]
  );

  useEffect(() => {
    updateLoader(imageLoaded);
  }, [imageLoaded, updateLoader]);

  useEffect(() => {
    if (loadingProgress >= 1 && entered && !animationStarted) {
      const cards = document.getElementsByClassName("movable_grid");
      if (!cards.length) return;
      setAnimatedStarted(true);
      gsap.set(cards, {
        zIndex: (i, _, targets) => {
          return targets.length + i;
        },
      });

      const animation = gsap.to(cards, {
        zIndex: (i, _, targets) => {
          return i !== 0 ? targets.length - i : targets.length;
        },
        stagger: {
          each: 0.2,
          from: "end",
        },
        onComplete: () => {
          spreadOut(positions);
        },
      });
      setStartingAnimation(animation);
    }
  }, [loadingProgress, positions, spreadOut, entered, animationStarted]);

  if (!copies) return null;

  return (
    <PageWrapper color={theme.textColor}>
      {/* {(true) && <Loading progress={loadingProgress} />} */}
      {/* {(!entered || (entered && !starterAnimation)) && (
        <Loading progress={loadingProgress} />
      )} */}

      {!animationCompelted && <BackgroundAnimation />}

      {stackingAnimationDone && (
        <StickySideBar
          copies={generalCopies}
          showSpeaker
          scrollToSpeaker={scrollToSpeaker}
        />
      )}

      {positions.map((p, idx) => (
        <div
          className="movable_grid"
          id={`movable_grid_${p.id}`}
          key={`${p.id}_${idx}`}
          style={{
            zIndex: idx,
            position: "absolute",
            left: window.innerWidth * 0.5 - p.width * 0.5,
            top:
              window.innerHeight * 0.5 -
              p.height * 0.5 -
              centerAnimationOffsetY,
            width: p.width,
            height: p.height,
          }}
        >
          <LandingPageGatsbyImage
            look={looks[idx]}
            disabled
            aspectRatio={
              gridSectionOne[idx]
                ? gridSectionOne[idx].width / gridSectionOne[idx].height
                : "auto"
            }
            gatsbyImageData={looks[idx].image.gatsbyImageData}
            style={{ height: "100%" }}
            loading="eager"
            onLoad={onImageLoaded}
          />
        </div>
      ))}

      <Wrapper>
        <Section1 ref={ref}>
          {gridSectionOne.map((grid) => (
            <GridItem
              area={grid.area}
              id={grid.area}
              key={grid.area}
              style={{
                transform: isMobile
                  ? grid.transform?.mobile
                  : grid.transform?.desktop,
              }}
            >
              {looks[grid.order].looks && (
                <LandingPageGatsbyImage
                  look={looks[grid.order]}
                  gatsbyImageData={looks[grid.order].image.gatsbyImageData}
                  style={{ height: "100%" }}
                  aspectRatio={grid.width / grid.height}
                  loading="eager"
                  onLoad={onImageLoaded}
                />
              )}
            </GridItem>
          ))}

          <HeaderGrid area="g" id="g" className="displayGrid">
            <h1>{copies.page_title}</h1>
            <Subtitle
              textAlign="center"
              lineHeight="18px"
              style={{ width: "150%" }}
            >
              {copies.title_description_1}
            </Subtitle>
          </HeaderGrid>
          <SpotifyGrid area="f" id="f" ref={speakRef} className="displayGrid">
            <SpotifyPlayer url={spotifyUrl} height={230} rounded />
            <Subtitle>{copies.spotify_description}</Subtitle>
          </SpotifyGrid>
        </Section1>
        {!isMobile ? (
          <Section2Desktop>
            <div />
            <LandingPageGatsbyImage
              look={looks[gridSectionTwo.desktop[0].order]}
              gatsbyImageData={
                looks[gridSectionTwo.desktop[0].order].image.gatsbyImageData
              }
              aspectRatio={
                gridSectionTwo.desktop[0].width /
                gridSectionTwo.desktop[0].height
              }
              style={{
                transform: isMobile
                  ? gridSectionTwo.desktop[0].transform?.mobile
                  : gridSectionTwo.desktop[0].transform?.desktop,
                height: "100%",
              }}
              loading="eager"
              onLoad={onImageLoaded}
            />
            <div />
            <AnimatableContainer className="displayGrid">
              <GatsbyImageComponent
                gatsbyImageData={attitudeImages.confident.gatsbyImageData}
                style={theme.imageFilter}
              />
            </AnimatableContainer>
          </Section2Desktop>
        ) : (
          <>
            <Section2MobileHeader>
              <GatsbyImageComponent
                gatsbyImageData={attitudeImages.confident.gatsbyImageData}
                style={theme.imageFilter}
              />
            </Section2MobileHeader>
            <SectionGrid
              component={Section2Mobile}
              data={gridSectionTwo.mobile}
              looks={looks}
            />
          </>
        )}
        {isMobile ? (
          <SectionGrid
            component={Section3Mobile}
            data={gridSectionThree.mobile}
            looks={looks}
          />
        ) : (
          <SectionGrid
            component={Section3Desktop}
            data={gridSectionThree.desktop}
            looks={looks}
            onLoad={onImageLoaded}
          />
        )}

        <SectionDescription>
          <div>
            <SectionHeader>{splitLines(copies.page_subtitle)}</SectionHeader>
          </div>
          {!isMobile && <Subtitle>{copies.page_subtitle_1}</Subtitle>}
        </SectionDescription>
        {isMobile ? (
          <SectionGrid
            component={SectionFourMobile}
            data={gridSectionFour.mobile}
            looks={looks}
          />
        ) : (
          <SectionGrid
            component={SectionFourDesktop}
            data={gridSectionFour.desktop}
            looks={looks}
            onLoad={onImageLoaded}
          />
        )}

        {isMobile ? (
          <>
            <MobileContentWrapper>
              <Subtitle>{copies.page_subtitle_1}</Subtitle>
            </MobileContentWrapper>

            <SectionGrid
              component={SectionFiveMobile}
              data={gridSectionFive.mobile}
              looks={looks}
            />
            <MobileContentWrapper>
              <Subtitle>{copies.page_subtitle_2}</Subtitle>
            </MobileContentWrapper>
          </>
        ) : (
          <SectionGrid
            component={SectionFiveDesktop}
            data={gridSectionFive.desktop}
            looks={looks}
            onLoad={onImageLoaded}
          >
            <Subtitle>{copies.page_subtitle_2}</Subtitle>
          </SectionGrid>
        )}

        {isMobile ? (
          <>
            <SectionGrid
              component={SectionSixMobile}
              data={gridSectionSix.mobile}
              looks={looks}
            />
            <MobileContentWrapper margin="60px 0" padding="24px">
              <GatsbyImageComponent
                gatsbyImageData={attitudeImages.chill.gatsbyImageData}
                style={theme.imageFilter}
              />
            </MobileContentWrapper>
          </>
        ) : (
          <SectionSixDesktop>
            <div>
              <SectionGrid
                component={DummyDiv}
                data={[gridSectionSix.desktop[0]]}
                looks={looks}
                onLoad={onImageLoaded}
              />
              <SectionHeader>{splitLines(copies.jeans_desc)}</SectionHeader>
            </div>

            <SectionGrid
              component={DummyDiv}
              data={gridSectionSix.desktop.slice(1)}
              looks={looks}
              onLoad={onImageLoaded}
            >
              <div>
                <GatsbyImageComponent
                  gatsbyImageData={attitudeImages.chill.gatsbyImageData}
                  style={theme.imageFilter}
                />
              </div>
            </SectionGrid>
          </SectionSixDesktop>
        )}

        {isMobile ? (
          <>
            <SectionGrid
              component={SectionSevenMobile}
              data={gridSectionSeven.mobile}
              looks={looks}
            />
            <MobileContentWrapper lineHeight="auto" padding="24px 12px">
              <SectionHeader textAlign="center">
                {splitLines(copies.jeans_desc)}
              </SectionHeader>
            </MobileContentWrapper>
          </>
        ) : (
          <SectionGrid
            looks={looks}
            data={gridSectionSeven.desktop}
            component={SectionSevenDesktop}
            onLoad={onImageLoaded}
          />
        )}

        {isMobile && (
          <>
            <SectionGrid
              component={SectionEightMobile}
              data={gridSectionEight.mobile}
              looks={looks}
            />
          </>
        )}
      </Wrapper>
      <PageFooter>
        <div>
          <BorderedButton onClick={exploreMore}>
            {copies.explore_more}
          </BorderedButton>
          <BorderedButton onClick={scrollToTop}>
            {generalCopies.back_to_top}
          </BorderedButton>
        </div>
      </PageFooter>
    </PageWrapper>
  );
};

export const query = graphql`
  query pageQuery(
    $pageIds: [String]
    $regionLooksId: String
    $generalCopiesIds: [String]
    $tagIds: [String]
    $attitudeImagesIds: [String]
    $ecommerceLinkIds: [String]
  ) {
    allPrismicDataRegionEcommerce(filter: { id: { in: $ecommerceLinkIds } }) {
      nodes {
        id
        lang
        data {
          region
          links {
            label
            link
          }
        }
      }
    }

    allPrismicCopiesHomepage(filter: { id: { in: $pageIds } }) {
      nodes {
        id
        lang
        alternate_languages {
          uid
          type
          lang
        }
        lang
        url
        type
        data {
          page_title
          main_title
          title_description_1
          spotify_description
          page_subtitle
          page_subtitle_1
          page_subtitle_2
          page_subtitle_3
          jeans_desc
          explore_more
        }
      }
    }
    allPrismicDataAttitudeImages(filter: { id: { in: $attitudeImagesIds } }) {
      nodes {
        id
        lang
        data {
          chill {
            gatsbyImageData(width: 500)
          }
          confident {
            gatsbyImageData(width: 500)
          }
        }
      }
    }
    allPrismicCopiesGeneral(filter: { id: { in: $generalCopiesIds } }) {
      nodes {
        id
        lang
        alternate_languages {
          uid
          type
          lang
        }
        lang
        url
        type
        data {
          back_to_top
          inspire_me
          about_the_jeans
          filter
          dark_mode
          light_mode
          meta: meta_homepage
        }
      }
    }
    allPrismicDataTag(filter: { id: { in: $tagIds } }) {
      nodes {
        id
        data {
          key
          category
          title
        }
      }
    }
    prismicDataLooksByRegion(id: { eq: $regionLooksId }) {
      id
      lang
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        region
        spotify_playlist
        looks {
          look {
            document {
              __typename
              ... on PrismicLook {
                id
                data {
                  lookid
                  kol
                  attitude
                  looks {
                    image {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
        landing_page_sequence {
          image {
            gatsbyImageData
          }
          individual {
            document {
              __typename
              ... on PrismicLook {
                id
                data {
                  lookid
                  kol
                  attitude
                  looks {
                    image {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default HomepageTemplate;

export { default as Head } from "../components/Head";
