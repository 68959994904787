import styled from "styled-components";
import { BREAKPOINTS } from "../contexts/languageContext";
import { menuBarHeight } from "../layouts";
import { mdBreakpoint, mobileBreakpoint } from "./theme";

export const PageWrapper = styled.div`
  color: ${({ color }) => color};
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Subtitle = styled.div`
  font-size: min(18px, 2vw);
  line-height: ${({ lineHeight }) => lineHeight || "min(28px, 2.5vw)"};
  margin: 8px 0;
  text-align: ${({ textAlign }) => textAlign || "left"};
  color: inherit;
  @media (max-width: ${BREAKPOINTS.desktop}px) {
    padding: 16px;
  }
  @media (max-width: ${mobileBreakpoint}) {
    font-size: 16px;
    line-height: 24px;
    padding: 0;
  }
`;
export const Section1 = styled.div`
  //   height: calc(100vh - ${menuBarHeight}px);
  // height: 105vh;
  display: grid;
  gap: 48px 96px;
  // grid-template-columns: 15% auto 5% 13% auto 8%;
  grid-template-columns: 0.7fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr;
  grid-template-rows: 0.2fr 0.4fr 0.2fr 0.2fr 0.1fr 0.75fr 0.25fr;
  grid-template-areas:
    "b b a a . ."
    "b b a a c c"
    ". . a a c c"
    ". . g g . ."
    "d . g g . ."
    "d e e f f ."
    "d e e f f .";

  @media (max-width: ${mdBreakpoint}) {
    gap: 48px;
  }
  @media (max-width: ${mobileBreakpoint}) {
    grid-template-columns: 45% 0.5fr 0.5fr 45%;
    flex: 1;
    gap: 16px;
    grid-template-rows: 1fr;
    grid-template-areas:
      "a a a ."
      "g g g g"
      ". b b b"
      "c b b b"
      "c . . ."
      "f f f f"
      "d d d ."
      "d d d e";
  }
`;

export const GridItem = styled.div`
  grid-area: ${({ area }) => area};
  position: relative;
  // opacity: 0;
  // background: rgba(0, 90, 90, 0.5);
  ${({ transform }) =>
    transform &&
    `
    transform: ${transform};
  `};
`;

export const HeaderGrid = styled(GridItem)`
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 60px;
  text-align: center;
  transform: translateX(-18%);
  & h1 {
    margin: 0;
    font-size: 66px;
  }
  @media (max-width: ${mdBreakpoint}) {
    transform: translate(-15%, -50%);
  }
  @media (max-width: ${mobileBreakpoint}) {
    align-items: center;
    transform: none;
    margin: 0;
    padding: 0 48px;
    & h1 {
      font-size: 48px;
      margin: 0;
    }
  }
`;

export const SectionHeader = styled.h1`
  font-size: 36px;
  font-weight: 400;
  margin: 0;
  text-align: ${({ textAlign }) => textAlign || "left"};
  @media (max-width: ${BREAKPOINTS.desktop}px) {
    padding-left: 24px;
  }
  @media (max-width: ${mobileBreakpoint}) {
    font-size: 28px;
  }
`;

export const SectionDescription = styled.div`
  display: grid;
  grid-gap: 48px;
  grid-template-columns: 1fr 0.9fr;
  font-size: 18px;
  margin-top: 72px;
  & > * {
    display: flex;
    align-items: end;
  }
  & ${SectionHeader} {
    padding-bottom: 80px;
  }

  @media (max-width: ${mobileBreakpoint}) {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    & ${SectionHeader} {
      padding: 16px;
    }
  }
`;

export const SpotifyGrid = styled(GridItem)`
  opacity: 0;
  width: 110%;
  @media (max-width: ${mobileBreakpoint}) {
    padding: 32px;
    width: 100%;
  }
`;

export const Section2Desktop = styled.div`
  margin-top: 96px;
  display: grid;
  grid-template-columns: 2% 15% 25% 40%;
  // grid-template-columns: 2% 15% 30% 30%;
`;

export const Section2Mobile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  & > div:nth-of-type(1) {
    margin-top: 48px;
  }
  & > div:nth-of-type(2) {
    padding: 16px;
  }
`;
export const Section2MobileHeader = styled.div`
  padding: 24px;
  margin-top: 48px;
`;

export const Section3Desktop = styled.div`
  display: grid;
  grid-gap: 48px;
  grid-template-columns: 1fr 1fr 1.2fr 1.1fr 1.1fr;
  & div:nth-of-type(1) {
    grid-area: a;
  }
  & div:nth-of-type(2) {
    grid-area: b;
  }
  & div:nth-of-type(3) {
    grid-area: c;
  }
  & div:nth-of-type(4) {
    grid-area: d;
  }
  & div:nth-of-type(5) {
    grid-area: e;
    margin-top: 48px;
  }
  grid-template-areas:
    ". c a a b"
    "d . a a e";
`;

export const Section3Mobile = styled.div`
  display: grid;
  margin: 24px 0 36px 0;
  grid-gap: 16px;
  grid-template-columns: 0.85fr 1.15fr;
  grid-template-areas: "b a";
  & div:nth-of-type(1) {
    grid-area: a;
  }
  & div:nth-of-type(2) {
    grid-area: b;
  }
`;

export const SectionFourMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  & div:nth-of-type(1) {
    grid-area: a;
  }
  & div:nth-of-type(2) {
    grid-area: b;
  }
  & div:nth-of-type(3) {
    grid-area: c;
  }
  grid-template-areas:
    "a a"
    ". b"
    "c b"
    "c .";
`;

export const SectionFourDesktop = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-gap: 48px;
  margin-top: 48px;

  & div:nth-of-type(1) {
    grid-area: a;
  }
  & div:nth-of-type(2) {
    grid-area: b;
  }
  & div:nth-of-type(3) {
    grid-area: c;
  }
  grid-template-areas: "c a b";
`;

export const MobileContentWrapper = styled.div`
  padding: 24px;
  line-height: ${({ lineHeight }) => lineHeight || "24px"};
  ${({ margin }) =>
    margin &&
    `
    margin: ${margin};
  `}
  ${({ padding }) =>
    padding &&
    `
    padding: ${padding};
  `}
`;

export const SectionFiveMobile = styled.div`
  margin: 24px 0;
`;

export const SectionFiveDesktop = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  grid-gap: 48px;
  margin-top: 100px;
  margin-bottom: -120px;
  & div:nth-of-type(1) {
    grid-area: a;
  }
  & div:nth-of-type(2) {
    grid-area: b;
  }
  & div:nth-of-type(3) {
    grid-area: c;
  }
  & div:nth-of-type(4) {
    grid-area: d;
  }
  & div:nth-of-type(5) {
    grid-area: e;
  }
  grid-template-areas:
    "a c b"
    "d c e"
    "d c e";
`;

export const SectionSixMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 96px 24px;
  margin-top: 24px;
  & div:nth-of-type(1) {
    grid-area: a;
  }
  & div:nth-of-type(2) {
    grid-area: b;
  }
  & div:nth-of-type(3) {
    grid-area: c;
  }
  & div:nth-of-type(4) {
    grid-area: d;
  }
  & div:nth-of-type(5) {
    grid-area: e;
  }
  grid-template-areas:
    "a b"
    "c c"
    "d e";
`;

export const DummyDiv = styled.div``;

export const SectionSixDesktop = styled.div`
  display: grid;
  grid-template-columns: 1.25fr 1fr;
  grid-gap: 48px;
  margin-top: 48px;
  & > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    & ${SectionHeader} {
      margin-top: 48px;
    }
  }
  & > div:nth-of-type(2) {
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    grid-gap: 48px;
    & > div:nth-of-type(1) {
      grid-area: a;
      margin-top: 48px;
    }
    & > div:nth-of-type(2) {
      grid-area: b;
    }
    & > div:nth-of-type(3) {
      grid-area: c;
    }
    grid-template-areas:
      "a a"
      ". b"
      "c b"
      "c .";
  }
`;

export const SectionSevenMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin-top: -16px;
  & div:nth-of-type(1) {
    grid-area: a;
  }
  & div:nth-of-type(2) {
    grid-area: b;
  }
  & div:nth-of-type(3) {
    grid-area: c;
  }
  & div:nth-of-type(4) {
    grid-area: d;
    margin-top: 60px;
  }
  grid-template-areas:
    "a a"
    "b c"
    ". ."
    ". ."
    ". ."
    "d d";
`;

export const SectionSevenDesktop = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.2fr 1fr;
  grid-gap: 24px;
  margin-top: 48px;
  & div:nth-of-type(1) {
    grid-area: a;
  }
  & div:nth-of-type(2) {
    grid-area: b;
  }
  & div:nth-of-type(3) {
    grid-area: c;
  }
  & div:nth-of-type(4) {
    grid-area: d;
  }
  & div:nth-of-type(5) {
    grid-area: e;
  }
  grid-template-areas:
    "b c a"
    "b c ."
    "b e d"
    ". e d";
`;

export const SectionEightMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  & div:nth-of-type(1) {
    grid-area: a;
  }
  & div:nth-of-type(2) {
    grid-area: b;
  }
  & div:nth-of-type(3) {
    grid-area: c;
  }
  & div:nth-of-type(4) {
    grid-area: d;
  }
  grid-template-areas:
    ". a"
    "b a"
    "b ."
    ". c"
    "d d";
`;

export const PageFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 96px;
  padding-bottom: 48px;
  & > div {
    display: flex;
    flex-direction: column;
    grid-gap: 48px;
  }
`;
export const AnimatableContainer = styled.div`
  opacity: 0;
`;
