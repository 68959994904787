import React from "react";
import { useLanguage } from "../contexts/languageContext";

const SpotifyPlayer = ({ rounded, url, height = "100%" }) => {
  const { entered } = useLanguage();
  if (!entered) return null;
  return (
    <iframe
      title="Spotify"
      style={{ borderRadius: rounded ? 12 : 0 }}
      src={`${url}?&theme=0`}
      width="100%"
      height={height}
      frameBorder="0"
      allowFullScreen=""
      //   allow="encrypted-media"
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"
    ></iframe>
  );
};
export default SpotifyPlayer;
